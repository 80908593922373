/** @jsx jsx */
import { jsx, AspectRatio } from 'theme-ui'
import ReactPlayer from 'react-player'

export const TrailerPlayer = ({
  url,
  image,
  ...rest
}: {
  url: string
  image?: string
  label?: string
}) => {
  return (
    <AspectRatio ratio={16 / 9} sx={{ bg: 'dark' }}>
      <ReactPlayer
        playing={true}
        url={url}
        width="100%"
        height="100%"
        playsinline
        light
        controls
        config={{
          youtube: {
            playerVars: { modestbranding: 1 },
          },
          vimeo: {
            playerOptions: {
              byline: false,
              color: 'blue',
              title: false,
            },
          },
        }}
        {...rest}
      />
    </AspectRatio>
  )
}
