import { SxStyleProp } from 'theme-ui'

const htmlStyles: SxStyleProp = {
  h2: {
    fontSize: 4,
    lineHeight: 'body',
    mb: 0,
    textTransform: 'uppercase',
  },
  h3: {
    fontSize: 3,
    lineHeight: 'body',
    mb: 0,
    textTransform: 'uppercase',
  },
  p: {
    fontSize: 2,
    lineHeight: 'body',
  },
  a: {
    color: 'blue',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  'h2 + p, h3 + p': {
    mt: 0,
  },
  img: {
    maxWidth: '100%',
  },
}

export const pageHtmlStyles: SxStyleProp = {
  fontSize: [1, 2],
  lineHeight: 'body',

  'h2, h3': {
    color: 'primary',
    fontSize: 4,
    lineHeight: 'body',
    mb: 0,
    textTransform: 'uppercase',
    textAlign: 'center',
    '& + p': {
      mt: 0,
    },
  },
  h3: {
    fontSize: 2,
  },
  'ul, ol': {
    my: 0,
    pl: '1em',
  },
  a: {
    color: 'yellow',
    '&:hover': {
      textDecoration: 'none',
    },
  },
  img: {
    maxWidth: '100%',
  },
}

export default htmlStyles
